.page-title {
  color: #1a1a1a;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.25;
  margin: 0 0 2em;
  text-align: center;
}

.page-subtitle {
  font-size: 14px;
}
